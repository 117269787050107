export const deliveryList: string[] = [
    'Barking and Dagenham aprox. £0.00-£7.00',
    'Barnet aprox. £20.00-£30.00',
    'Bexley aprox. £17.00-£25.00',
    'Brent aprox. £22.00-£35.00',
    'Bromley aprox. £16.00-£38.00',
    'Camden aprox. £16.00-£27.00*',
    'The City of London aprox. £14.00-£16.00*',
    'Croydon aprox. £24.00-£51.00',
    'Ealing aprox. £25.00-£41.00',
    'Enfield aprox. £13.00-£25.00',
    'Greenwich aprox. £12.00-£19.00',
    'Hackney aprox. £14.00-£19.00*',
    'Hammersmith and Fulham aprox. £22.00-£30.00',
    'Harrow aprox. £28.00-£38.00',
    'Haringey aprox. £15.00-£25.00',
    'Havering aprox. £7.00-£15.00',
    'Hillingdon aprox. £44.00-£48.00',
    'Hounslow aprox. £33.00-£40.00',
    'Islington aprox. £15.00-£23.00*',
    'Kensington and Chelsea aprox. £20.00-£25.00',
    'Kingston aprox. £26.00-£38.00',
    'Lambeth aprox. £16.00-£24.00*',
    'Lewisham aprox. £14.00-£22.00',
    'Merton aprox. £24.00-£29.00',
    'Newham aprox. £4.00-£13.00',
    'Redbridge aprox. £1.00-£13.00',
    'Richmond aprox. £27.00-£38.00',
    'Southwark aprox. £13.00-£26.00*',
    'Sutton aprox. £28.00-£34.00',
    'Tower Hamlets aprox. £9.00-£14.00*',
    'Waltham Forest aprox. £9.00-£17.00',
    'Wandsworth aprox. £18.00-£26.00',
    'Westminster aprox. £15.00-£24.00*'
]

export const deliveryInfo: string =
    'DeliveryList is available throughout all of London and beyond NewOrder collection is also available and it is free of charge'
