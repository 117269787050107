import React, { FC } from 'react'
import { List, ListItem, LiteText, Text, Title, Wrapper } from './DeliveryPageStyled'

import { deliveryList } from '../../Constants/PagesTexts/delivery'
import { LineLink } from '../../UI/Common/common'

const DeliveryPage: FC = () => {
    return (
        <Wrapper>
            <Title>Delivery</Title>
            <Text>
                Delivery is available throughout all of London and beyond. The price of delivery is
                calculated from point A to <br></br> point B, along the optimal route. We always provide a time slot for
                the delivery. Every delivery
                is individual
            </Text>
            <Text>
                Delivery cost by London Boroughs:
            </Text>
            <List>
                {deliveryList.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
            </List>
            <Text>
                If your Borough is not listed, please contact us directly for delivery prices
            </Text>
            <Text>
                Delivery cost is £1.25 per one mile, the cost of delivery increases if the route passes through toll
                roads, bridges and tunnels. Also,
                the customer pays for the cost of parking when delivering orders to shopping centres and entertainment
                establishments with paid
                parking.
                All deliveries must be checked by an authorised person at the time of delivery
            </Text>
            <Text>
                *- additional charges may apply. The Congestion Charge is a £15.00 daily charge if you drive within the
                Congestion Charge zone
                7:00-18:00 Monday – Friday and 12:00-18:00 Sat – Sun and bank holidays. No charge between Christmas Day
                and New Year’s Day bank
                holiday (inclusive). <LineLink href='https://tfl.gov.uk/modes/driving/congestion-charge'
                                               target='_blank'>https://tfl.gov.uk/modes/driving/congestion-charge</LineLink>
            </Text>
            <Text>
                Almost 100% of orders are delivered on time and there was never a case when a celebration was left
                without dessert
            </Text>
            <Text>
                However London is a huge city and there are situations on the roads that cannot be foreseen in advance,
                delays are possible.
                In any case, we do our best to ensure that your order is delivered on time
            </Text>
            <Text>
                Uber collection
            </Text>
            <LiteText>
                How it works:
            </LiteText>
            <LiteText>
                • When your order is ready for collection we’ll contact you sending a text message with collection info
            </LiteText>
            <LiteText>
                • You have to arrange order collection using the Uber app with our location as a pickup point
            </LiteText>
            <LiteText>
                • Send us information of the vehicle Vehicle Reg Number, Vehicle Mark and Time of arriving
            </LiteText>
            <LiteText>
                • We’ll meet the driver, pass them your order with instruction of delivery*
            </LiteText>
            <LiteText>
                • Track the driver trough Uber app and meet them upon arrival
            </LiteText>
            <LiteText>
                * In that case, Jelena's Cake Ltd is not responsible for the safety of the products in transit, since we
                are unable to control all the aspects of transportation
            </LiteText>
            <Text>
                Royal Mail
            </Text>
            <LiteText>
                Order will be send by Royal Mail Postage*
            </LiteText>
            <LiteText>
                * In that case, Jelena's Cake Ltd is not responsible for the safety of the products in transit, since we
                are unable to control all the aspects of transportation
            </LiteText>
            <Text>
                Order collection is also available and it is free of charge. In that case, Jelena's Cake Ltd is not
                responsible for the safety of the
                products in transit, since we are unable to control all the aspects of transportation and all
                responsibility rests on the person who
                collected the order
            </Text>
        </Wrapper>
    )
}

export default DeliveryPage
