import styled from 'styled-components'
import { MainText, MainTitle } from '../../UI/Common/common'

export const Wrapper = styled.div`
  margin: 60px 0 0 0;

  @media (${({ theme }) => theme.media.mobile}) {
    max-width: calc(100vw - 40px);
  }
`

export const Title = styled(MainTitle)`
  margin: 0;
  text-align: center;
`

export const Text = styled(MainText)`
  margin: 40px 0 0 0;
`

export const LiteText = styled(MainText)`
  margin: 10px 0 0 0;
`

export const List = styled.div`
  margin: 30px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0 0 0;
    grid-template-columns: 1fr;
  }
`

export const ListItem = styled(MainText)`
  margin: 0 0 10px 0;
  font-size: 14px;
`
