import { FC, useEffect, useState } from 'react'
import '../../Styles/fonts.css'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { setMobile } from '../../store/reducers/AppCondition/AppConditionSlice'
import Layout from '../Layout/Layout'
import HomePage from '../../pages/HomePage/HomePage'
import Account from '../../pages/Account/Account'
import Profile from '../../pages/Profile/Profile'
import Orders from '../../pages/Orders/Orders'
import Order from '../../pages/Order/Order'
import Cart from '../../pages/Cart/Cart'
import AboutAsPage from '../../pages/AboutAsPage/AboutAsPage'
import DeliveryPage from '../../pages/DeliveryPage/DeliveryPage'
import ContactsPage from '../../pages/ContactsPage/ContactsPage'
import FAQPage from '../../pages/FAQPage/FAQPage'
import PricePage from '../../pages/PricePage/PricePage'
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage/PrivacyPolicyPage'
import TermsPage from '../../pages/TermsPage/TermsPage'
import ShopPage from '../../pages/ShopPage/ShopPage'
import { getProfile } from '../../store/reducers/User/ActionCreators'
import { IAppOptionsState } from '../../store/reducers/AppOptions/AppOptionsSlice'
import { IProductsPersistState } from '../../store/reducers/ProductsSlice/ProductsSlice'
import Transactions from '../../pages/Transactions/Transactions'
import Payment from '../../pages/Payment/Payment'
import { ProductPageWrapper } from '../../pages/ProductPage/ProductPageWrapper'

const App: FC = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { productsSections } = useAppSelector(
        (state) => state.productReducer as IProductsPersistState
    )
    const { isAuth } = useAppSelector(
        (state) => state.appOptionsReducer as IAppOptionsState
    )
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        if (window.screen.width <= 844) {
            dispatch(setMobile())
        }
    }, [])

    useEffect(() => {
        if (!isAuth) {
            setIsLoaded(true)
            return
        }
        const getUser = async () => {
            if (isAuth) {
                await dispatch(getProfile())
            }
            setIsLoaded(true)
        }

        getUser()

        const interval = setInterval(() => {
            getUser()
        }, 30000)

        return () => {
            clearInterval(interval)
        }
    }, [isAuth])

    if (!isLoaded) {
        return null
    }

    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route path='sections' element={<Outlet />}>
                    <Route path=':sectionId' element={<Outlet />}>
                        <Route index element={<ShopPage />} />
                        <Route path=':productId' element={<ProductPageWrapper />} />
                    </Route>
                </Route>
                <Route path='*' element={<Navigate to='/' replace />} />
                <Route index element={<HomePage />} />
                <Route path='/about' element={<AboutAsPage />} />
                <Route path='/delivery' element={<DeliveryPage />} />
                <Route path='/contacts' element={<ContactsPage />} />
                <Route path='/f_a_q' element={<FAQPage />} />
                <Route path='/price' element={<PricePage />} />
                <Route path='/privacy_policy' element={<PrivacyPolicyPage />} />
                <Route path='/terms' element={<TermsPage />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/cart/:action' element={<Cart />} />
                <Route path='/payment/:id' element={<Payment />} />
                <Route path='/account' element={<Account />}>
                    <Route
                        path='/account'
                        element={<Navigate to='/account/orders' replace />}
                    />
                    <Route path='/account/profile' element={<Profile />} />
                    <Route path='/account/orders' element={<Orders />} />
                    <Route path='/account/order' element={<Order />} />
                    <Route path='/account/transactions' element={<Transactions />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default App
