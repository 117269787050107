export const getStringDate = (date: string | Date) => {
    if (!date) return ''
    const currentDate = new Date(date)
    let day: string | number = currentDate.getDate()
    if (day < 10) {
        day = `0${day}`
    }
    let month: string | number = currentDate.getMonth() + 1
    if (month < 10) {
        month = `0${month}`
    }
    const yer = currentDate.getFullYear()
    return `${day}.${month}.${yer}`
}