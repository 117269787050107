import { ILink } from '../../../Types/ILink'

export const navigationLinks: ILink[] = [
    {
        title: 'Home',
        path: '/'
    },
    {
        title: 'About Us',
        path: '/about'
    },
    {
        title: 'Delivery',
        path: '/delivery'
    },
    {
        title: 'Contacts',
        path: '/contacts'
    },
    {
        title: 'Prices',
        path: '/price'
    }
]

export const rightsLinks: ILink[] = [
    {
        title: 'FAQ',
        path: '/f_a_q'
    },
    {
        title: 'Privacy Policy',
        path: '/privacy_policy'
    },
    {
        title: 'Terms & Conditions',
        path: '/terms'
    }
]