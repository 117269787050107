import styled from 'styled-components'
import { Logo } from '../../UI/Common/common'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
    position: relative;
    margin: 190px 0 0 0;
    padding: 90px 0 100px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.graphics};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 60px 0 0 0;
        padding: 40px 0 60px 0;
        flex-wrap: wrap;
    }
`

export const FooterLogo = styled(Logo)`
    width: 350px;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 80%;
        height: auto;
    }
`

export const Container = styled.div`
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`

export const Description = styled.p`
    margin: 30px 0 0 0;
    max-width: 300px;
    font-size: 16px;

    @media (${({ theme }) => theme.media.mobile}) {
        text-align: center;
    }
`

export const Social = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (${({ theme }) => theme.media.mobile}) {
    }
`

export const SocialMessage = styled.p`
    margin: 0 7px 0 0;
    font-size: 16px;
`

export const SocialLink = styled.a`
    margin: 0 7px 0 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
`

export const SocialLinkImage = styled.img.attrs({
    alt: 'social'
})`
    width: 100%;
    height: 100%;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 30px 0 0 0;
    }
`

export const ListLink = styled(Link)`
    margin: 0 0 8px 0;
    font-size: 16px;
    text-decoration: none;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.colors.graphics};
    opacity: 0.8;
    transition: 0.125s;

    :hover {
        opacity: 1;
    }
`

export const Info = styled.p`
    position: absolute;
    bottom: 30px;
    right: 0;
    font-size: 16px;
`
